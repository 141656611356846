import React from 'react';

function getPaq() {
    const paq = window._paq || [];
    window._paq = paq;
    return paq;
}

export function initAnalytics(url) {
    const paq = getPaq();
    paq.push(['setTrackerUrl', url + 'matomo.php']);
    paq.push(['setSiteId', '3']);
    const d = document,
        g = d.createElement('script'),
        s = d.getElementsByTagName('script')[0];
    g.async = true;
    g.src = url + 'matomo.js';
    s.parentNode?.insertBefore(g, s);
    paq.push(['enableHeartBeatTimer', 30]);
    paq.push(['enableLinkTracking']);
}

function googleScript(s, l, i) {
    if (typeof window === 'undefined' || window.document === undefined) {
        return;
    }
    let w = window;
    let d = w.document;
    w[l] = w[l] || [];
    w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
    });
    var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
}

function googleScript2() {
    if (typeof window === 'undefined') {
        return;
    }
    window.dataLayer = window.dataLayer || [];
    function gtag(...params) {
        window.dataLayer.push(params);
    }
    gtag('js', new Date());
    gtag('config', 'G-P1QPMGRP7J');
}

const MatomoInit = ({ children }) => {
    React.useEffect(() => {
        let matomoUrl = 'https://matomo.flexhero-service.de/';
        if (
            window.location.hostname === 'localhost' ||
            window.location.hostname === 'publicprojects.web.app' ||
            window.location.hostname === 'publicprojects.firebaseapp.com'
        ) {
            matomoUrl = 'https://matomo.flexhero-online.de/';
        }
        initAnalytics(matomoUrl);
        googleScript('script', 'dataLayer', 'GTM-KLPTQLV');
        googleScript2();
    }, []);
    return <>{children}</>;
};

export const wrapRootElement = ({ element }) => {
    return (
        <>
            {/*<!-- Google Tag Manager (noscript) -->*/}
            <noscript>
                <iframe
                    src="https://www.googletagmanager.com/ns.html?id=GTM-KLPTQLV"
                    height="0"
                    width="0"
                    style={{ display: 'none', visibility: 'hidden' }}
                />
            </noscript>
            {/*<!-- End Google Tag Manager (noscript) -->*/}
            <MatomoInit>{element}</MatomoInit>
        </>
    );
};
