import { createTheme } from '@mui/material';

const colorMode = 'light'; //prefersDarkMode ? 'dark' : 'light';
const lightMode = colorMode === 'light';

const theme = createTheme({
    palette: {
        mode: colorMode,
        primary: {
            main: '#429B8F',
            light: '#429B8F',
            dark: '#429B8F',
            contrastText: '#ffffff',
        },
        success: {
            main: '#429B8F',
            light: '#429B8F',
            dark: '#429B8F',
        },
        secondary: {
            main: '#3d484a',
            contrastText: '#ffffff',
        },
        ...(lightMode
            ? {
                  //light mode colors
                  background: {
                      default: '#ffffff',
                      paper: '#ffffff',
                  },
              }
            : {
                  //dark mode colors
                  background: {
                      default: '#37474f',
                      paper: '#37474f',
                  },
              }),
    },
    components: {
        MuiRadio: {
            styleOverrides: {
                root: {
                    '&.Mui-checked': {
                        color: '#429B8F',
                    },
                },
            },
        },
    },
});

export default theme;
